// ** React Imports
import { useEffect } from "react";

// ** Third Party Components
import { window } from "ssr-window";

// ** Redux & Store & Actions
import { useSelector, useDispatch } from "react-redux";
import { setPWAInstallStatus, setPWAInstallEvent } from "store/modules/global";

export default function usePWAInstalable() {
	const pwaOption = useSelector(({ global: { pwa } }) => pwa);

	const installStatus = pwaOption?.status;
	const installEvent = pwaOption?.event;
	const dispatch = useDispatch();

	const createStatus = (object) => {
		return {
			isInstallAllowed: Object.prototype.hasOwnProperty.call(
				object,
				"isInstallAllowed"
			)
				? object.isInstallAllowed
				: false,
			isInstallWatingConfirm: Object.prototype.hasOwnProperty.call(
				object,
				"isInstallWatingConfirm"
			)
				? object.isInstallWatingConfirm
				: false,
			isInstalling: Object.prototype.hasOwnProperty.call(
				object,
				"isInstalling"
			)
				? object.isInstalling
				: false,
			isInstallCancelled: Object.prototype.hasOwnProperty.call(
				object,
				"isInstallCancelled"
			)
				? object.isInstallCancelled
				: false,
			isInstallSuccess: Object.prototype.hasOwnProperty.call(
				object,
				"isInstallSuccess"
			)
				? object.isInstallSuccess
				: false,
			isInstallFailed: Object.prototype.hasOwnProperty.call(
				object,
				"isInstallFailed"
			)
				? object.isInstallFailed
				: false,
		};
	};

	const beforeAppInstallpromptHandler = (e) => {
		e.preventDefault();

		if (!installStatus.isInstalling) {
			if (!installStatus.isInstallSuccess) {
				dispatch(setPWAInstallEvent(e));
				if (!installStatus.isInstallAllowed) {
					dispatch(
						setPWAInstallStatus(
							createStatus({
								isInstallAllowed: true,
								isInstallCancelled:
									installStatus.isInstallCancelled,
							})
						)
					);
				}
			}
		}
	};

	const appInstalledHandler = (e) => {
		if (!installStatus.isInstallSuccess) {
			window.removeEventListener(
				"beforeinstallprompt",
				beforeAppInstallpromptHandler
			);
			e.preventDefault();

			if (localStorage && typeof localStorage.setItem === "function") {
				localStorage.setItem("isInstallSuccessPWA", true);
			}

			dispatch(
				setPWAInstallStatus(createStatus({ isInstallSuccess: true }))
			);
		}
	};

	useEffect(() => {
		window.addEventListener(
			"beforeinstallprompt",
			beforeAppInstallpromptHandler
		);
		window.addEventListener("appinstalled", appInstalledHandler);

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				beforeAppInstallpromptHandler
			);
			window.removeEventListener("appinstalled", appInstalledHandler);

			createStatus({});
		};
	}, []);

	const handleOnInstall = () => {
		dispatch(
			setPWAInstallStatus(createStatus({ isInstallWatingConfirm: true }))
		);

		installEvent.prompt();
		installEvent.userChoice
			.then((choiceResult) => {
				if (choiceResult.outcome === "accepted") {
					dispatch(
						setPWAInstallStatus(
							createStatus({
								isInstalling: true,
								isInstallAllowed: false,
							})
						)
					);
				} else {
					dispatch(
						setPWAInstallStatus(
							createStatus({
								isInstallCancelled: true,
								isInstallAllowed: true,
							})
						)
					);
				}
			})
			.catch(() => {
				dispatch(
					setPWAInstallStatus(
						createStatus({
							isInstallFailed: true,
							isInstallAllowed: true,
						})
					)
				);
			});
		dispatch(setPWAInstallEvent(null));
	};

	return { handleOnInstall, installStatus, installEvent };
}
