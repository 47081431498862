import jwtDecode from "jwt-decode";

export default function checkTokenExist(cookie) {
	try {
		jwtDecode(cookie);
	} catch (err) {
		return false;
	}
	return true;
}
