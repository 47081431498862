// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledAppWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	overflow: hidden;
	color: ${({ theme: { colors } }) => colors.black};
`;

// #####################################################
