import { useEffect, useRef } from "react";

const useWindowScrollbarWidth = () => {
	const verticalScrollbarWidth = useRef(0);

	useEffect(() => {
		const handleScroll = () => {
			const isVerticalScroll =
				document?.documentElement?.scrollHeight > window?.innerHeight;
			const verticalScrollWidth =
				window?.innerWidth - document?.documentElement?.clientWidth;

			verticalScrollbarWidth.current =
				isVerticalScroll &&
				verticalScrollWidth > 0 &&
				verticalScrollWidth < 28
					? verticalScrollWidth
					: 0;
		};

		handleScroll(); // Sprawdzenie szerokości scrolla na początku

		window.addEventListener("resize", handleScroll);

		return () => {
			window.removeEventListener("resize", handleScroll);
		};
	}, []);

	return verticalScrollbarWidth.current;
};

export default useWindowScrollbarWidth;
