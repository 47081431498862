export function round(value, decimals) {
	return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export function floor(value) {
	return Math.floor(value * 10) / 10;
}

export function convertToMilion(value) {
	return Number(Math.abs(Number(value)) / 1.0e6);
}
