const getMobileDetect = (userAgent) => {
	const isAndroid = () => Boolean(userAgent.match(/Android/i));
	const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
	const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
	const isChrome = () => Boolean(userAgent.match(/Chrome/i));
	const isSafari = () => Boolean(userAgent.match(/Safari/i) && !isChrome());
	const isFirefox = () => Boolean(userAgent.match(/Firefox/i));
	const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
	const isSSR = () => Boolean(userAgent.match(/SSR/i));

	const isMobile = () =>
		Boolean(isAndroid() || isIos() || isOpera() || isWindows());
	const isDesktop = () => Boolean(!isMobile() && !isSSR());

	return {
		isMobile,
		isDesktop,
		isAndroid,
		isIos,
		isSafari,
		isSSR,
		isFirefox,
	};
};
const useMobileDetect = (customUserAgent = null) => {
	const userAgent =
		customUserAgent ||
		(typeof navigator === "undefined" ? "SSR" : navigator.userAgent);
	return getMobileDetect(userAgent);
};

module.exports = useMobileDetect;
