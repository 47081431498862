// ** React Imports
import { useEffect } from "react";

// ** Redux & Store & Actions
import { useDispatch, useSelector } from "react-redux";
import {
	setDarkModeFromBrowser as setDarkModeFromBrowserAction,
	setDarkModeByUser as setDarkModeByUserAction,
} from "store/modules/global";

// ** Third Party Components
import cookie from "js-cookie";

// ** Configuration
import configGlobal from "configuration";

// #####################################################

const useDarkMode = () => {
	const dispatch = useDispatch();

	const isDarkMode = useSelector((state) => state.global?.darkMode?.darkMode);

	const isChangedByUser = useSelector(
		(state) => state.global?.darkMode?.isChangedByUser
	);

	const toggleTheme = () => {
		dispatch(setDarkModeByUserAction(!isDarkMode, true));
		const themeName = !isDarkMode ? "dark" : "light";
		cookie.set(configGlobal.theme.cookieName, themeName, {
			...configGlobal.auth.cookieOptionsJsCookie,
			expires: 365,
			secure: true,
		});
	};

	useEffect(() => {
		if (!isChangedByUser) {
			dispatch(
				setDarkModeFromBrowserAction(
					(window &&
						window.matchMedia &&
						window.matchMedia("(prefers-color-scheme: dark)")
							.matches) ||
						false
				)
			);
		}
	}, [isChangedByUser]);

	// #####################################################

	return { isDarkMode, toggleTheme };
};

// #####################################################

export default useDarkMode;

// #####################################################
