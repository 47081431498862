import { useState, useEffect } from "react";
import { window } from "ssr-window";
//import { breakpoints } from "../styles/grid";
import { size as breakpoints } from "styles/declares";

function useBreakpoint(name, type) {
	const pixels = breakpoints[name];
	function getMatchMediaQuery(ctype) {
		if (ctype === "min") {
			return `(min-width: ${pixels}px)`;
		}
		// Na max-width jest -1 pixel, żeby nie robiły się bugi na breakpointach np. 600/1050px, więcej w tasku DZ-2263.
		return `(max-width: ${pixels - 1}px)`;
	}
	const matchMediaQuery = getMatchMediaQuery(type);
	const [match, setMatch] = useState(
		typeof window !== "undefined"
			? window.matchMedia(matchMediaQuery).matches
			: false
	);
	useEffect(() => {
		function onMatch(evt) {
			setMatch(evt.matches);
		}
		const matcher = window.matchMedia(matchMediaQuery);
		matcher.addListener(onMatch);
		return () => {
			matcher.removeListener(onMatch);
		};
		// eslint-disable-next-line
	}, [pixels]);
	return match;
}

export default useBreakpoint;
