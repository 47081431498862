const breakpoints = {
	phoneS: 320,
	phoneM: 375,
	phone: 600,
	tablet: 600,
	desktop: 1050,
	giant: 1340,
	veryGiant: 1340,
};

export { breakpoints };
export default {
	breakpoints,
	row: {
		padding: 0,
	},
	col: {
		padding: 0,
	},
	container: {
		padding: 0,
		maxWidth: {
			...breakpoints,
		},
	},
};
